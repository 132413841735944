.project-header {
    @include margin;
    text-align: center;

    h1 {
        margin: 0 0 10px 0;
        padding: 20px;
        animation: fadeIn 0.6s ease 0.4s both;

        @media #{$sbp} {
            padding: 30px 10px 40px;
        }

        @media #{$mbp} {
            padding: 40px 10px 45px;
            margin-bottom: 15px;
        }

        @media #{$lbp} {
            margin-bottom: 20px;
        }

        @media #{$xbp} {
            margin-bottom: 30px;
        }

        @media #{$wbp} {
            padding: 40px 0 80px 0;
            margin-bottom: 0;
        }
    }

    > div {
        position: relative;
    }

    img {
        animation: fadeIn 0.8s ease 0.6s both;
        height: auto;
        background: #1a1a1a;
    }

    button {
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        filter: drop-shadow(0 0 3px black);
        height: 40px;
        width: 40px;
        display: block;

        @media #{$sbp} {
            height: 50px;
            width: 50px;
        }

        @media #{$mbp} {
            height: 60px;
            width: 60px;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}

.project-main {
    @include contain;
    @include margin;

    @media #{$mbp} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.project-content {
    margin-bottom: 30px;

    @media #{$sbp} {
        margin-bottom: 40px;
    }

    @media #{$mbp} {
        margin-bottom: 0;
    }

    p {
        max-width: 700px;
    }

    &.no-ref {
        p {
            max-width: 1000px;
        }
    }
}

.project-meta {
    list-style: none;
    margin: 30px 0 0 0;

    @media #{$xbp} {
        // margin-top: 40px;
    }

    li {
        line-height: 1.4;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @media #{$wbp} {
            align-items: center;
            display: flex;
        }
    }

    h5 {
        margin: 0 0 5px 0;
        font-weight: $semibold;

        @media #{$wbp} {
            min-width: 160px;
            margin: 0 5px 0 0;
        }
    }

    span {
        display: block;
    }
}

.project-testimonial {
    background: #000;
    padding: 20px;

    @media #{$mbp} {
        padding: 40px;
        margin-left: 50px;
        margin-top: 50px;
        max-width: 500px;
    }

    @media #{$lbp} {
        padding: 50px;
        margin-left: 60px;
    }

    @media #{$xbp} {
        padding: 60px;
        margin-left: 70px;
    }

    @media #{$wbp} {
        padding: 70px;
        margin-left: 120px;
    }

    q {
        display: block;
    }

    cite {
        display: block;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: 1.5px;
        font-size: 14px;

        @media #{$lbp} {
            letter-spacing: 1.7px;
            font-size: 15px;
        }
    }
}

.project-gallery {
    @include contain;
    @include margin;
    padding-top: 10px;
    padding-bottom: 10px;

    @media #{$xbp} {
        padding-top: 0;
        padding-bottom: 0;
    }

    button {
        position: absolute;
        bottom: 0;
        padding: 10px;
        background: $black;
        background: rgba($black, 0.8);
        border-radius: 0;

        @supports (backdrop-filter: blur(14px)) {
            background: rgba($black, 0.6);
            backdrop-filter: blur(14px);
        }

        @media #{$xsbp} {
            padding: 15px;
        }

        @media #{$sbp} {
        }

        @media #{$mbp} {
            padding: 30px 40px;
        }

        @media #{$lbp} {
        }

        @media #{$xbp} {
        }

        @media #{$wbp} {
            padding: 40px 60px;
        }
    }

    .gallery-images {
        position: relative;

        > div {
            img {
                display: none;
                width: 100%;

                &:first-child {
                    display: block;
                }
            }
        }
    }
}

.other-projects-header {
    @include contain;
    padding-top: 20px;
    margin-bottom: 10px;
    animation: fadeIn 0.6s ease 0.7s both;

    @media #{$lbp} {
        margin-bottom: 50px;
    }

    @media #{$wbp} {
        margin-bottom: 70px;
    }
}
