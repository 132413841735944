$aos-distance: 20px;

// Layout
$maxwidth: 1400px;

// Colours
$black: #121212;
$white: #fff;
$lightgrey: #f6f9fc;
$grey: #9b9b9b;
$darkgrey: #656565;
$colorPrimary: #00a1f2;
$error: #e83d3d; // Fonts
$fontStack: "Work Sans", sans-serif;

$fontSerif: "Libre Baskerville", serif;

$thin: 200;
$light: 300;
// $regular: 400;
// $medium: 500;
$semibold: 600;
// $bold: 800;

$border: 1px solid rgba($white, 0.2);

// Breakpoints
$xsbp: "screen and (min-width: 375px)";
$sbp: "screen and (min-width: 500px)";
$mbp: "screen and (min-width: 768px)";
$lbp: "screen and (min-width: 950px)";
$xbp: "screen and (min-width: 1140px)";
$wbp: "screen and (min-width: 1300px)";

// Mixins
@mixin mq($breakpoint) {
    @media screen and (min-width: #{$breakpoint}) {
        @content;
    }
}

@mixin minmax($from, $to) {
    @media screen and (min-width: #{$from}) and (max-width: #{$to}) {
        @content;
    }
}

@mixin borderRadius() {
    border-radius: 7px;

    @media #{$lbp} {
        border-radius: 15px;
    }
}

@mixin card() {
    @include boxShadow;
    @include borderRadius;
    background: $lightBlue;
}

@mixin margin($half: false) {
    margin-bottom: 40px;

    @media #{$sbp} {
        margin-bottom: 50px;
    }

    @media #{$mbp} {
        margin-bottom: 70px;
    }

    @media #{$lbp} {
        margin-bottom: 80px;
    }

    @media #{$xbp} {
        margin-bottom: 120px;
    }

    @media #{$wbp} {
        margin-bottom: 140px;
    }

    // @if $half {
    //
    // }
}

@mixin paddingTop() {
    padding-top: 40px;

    @media #{$sbp} {
        padding-top: 50px;
    }

    @media #{$mbp} {
        padding-top: 70px;
    }

    @media #{$lbp} {
        padding-top: 100px;
    }

    @media #{$xbp} {
        padding-top: 110px;
    }

    @media #{$wbp} {
        padding-top: 120px;
    }
}

@mixin paddingBottom() {
    padding-bottom: 40px;

    @media #{$sbp} {
        padding-bottom: 50px;
    }

    @media #{$mbp} {
        padding-bottom: 70px;
    }

    @media #{$lbp} {
        padding-bottom: 100px;
    }

    @media #{$xbp} {
        padding-bottom: 110px;
    }

    @media #{$wbp} {
        padding-bottom: 120px;
    }
}

@mixin mainHeadings {
    font-size: 22px;
    font-family: $fontStack;
    margin-bottom: 15px;

    @media #{$sbp} {
        font-size: 25px;
        line-height: 1.4;
    }

    @media #{$mbp} {
        font-size: 27px;
        margin-bottom: 10px;
    }

    @media #{$lbp} {
        font-size: 30px;
        margin-bottom: 15px;
    }

    @media #{$xbp} {
        font-size: 34px;
    }

    @media #{$wbp} {
        font-size: 36px;
    }
}

@mixin placeholders($color: lighten(grey, 40%), $fontSize: 16px) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color;
        font-size: 16px;

        @media #{$lbp} {
            font-size: $fontSize;
        }
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color;
        font-size: 16px;

        @media #{$lbp} {
            font-size: $fontSize;
        }
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $color;
        font-size: 16px;

        @media #{$lbp} {
            font-size: $fontSize;
        }
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color;
        font-size: 16px;

        @media #{$lbp} {
            font-size: $fontSize;
        }
    }
}

@mixin contain($horizontalPadding: 20px) {
    max-width: $maxwidth;
    margin-left: auto;
    margin-right: auto;

    @if $horizontalPadding !=0 {
        padding-left: $horizontalPadding;
        padding-right: $horizontalPadding;

        @media #{$xsbp} {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media #{$sbp} {
            padding-left: 50px;
            padding-right: 50px;
        }

        @include mq(600px) {
        }

        @media #{$mbp} {
            padding-left: 60px;
            padding-right: 60px;
        }

        @media #{$lbp} {
            padding-left: 70px;
            padding-right: 70px;
        }

        @media #{$xbp} {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}

@mixin boxShadow() {
    box-shadow: 0 20px 60px 0 rgba(37, 44, 82, 0.1);

    @media #{$mbp} {
        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.25);
    }

    @media #{$wbp} {
        box-shadow: 0 66px 80px 0 rgba(0, 0, 0, 0.25);
    }
}
