.card {
    @media #{$lbp} {
        border-top: 10px solid $colorPrimary;
        background: black;
        padding: 30px 40px 40px;
    }

    @media #{$xbp} {
        padding: 40px 50px 50px;
    }

    @media #{$wbp} {
        padding: 60px 70px 70px;
    }
}