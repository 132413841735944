.home-header {
    @include contain;
    min-height: 80vh;
    display: flex;
    align-items: center;

    @media #{$lbp} {
        padding: 0;
    }
}

.inner-header {
    @include contain;
    min-height: 40vh;
    display: flex;
    align-items: center;

    @media #{$lbp} {
        padding: 0;
    }
}
