.team-header {
    text-align: center;
    padding: 40px 25px 50px;
    max-width: 360px;
    margin: auto;

    @media #{$sbp} {
        padding: 40px 40px 60px;
        max-width: 490px;
    }

    @media #{$mbp} {
        max-width: 650px;
    }

    @media #{$lbp} {
        max-width: 850px;
        padding: 50px 40px 70px;
    }

    @media #{$xbp} {
        padding: 70px 40px 90px;
    }

    @media #{$wbp} {
        padding: 70px 40px 100px;
    }
}

.team {
    @include contain;
    @include margin;

    ul {
        list-style: none;
        margin: 0 auto 50px;

        @media #{$xsbp} {
            margin-bottom: 60px;
        }

        @media #{$sbp} {
            margin-bottom: 70px;
        }

        @media #{$mbp} {
            margin-bottom: 80px;
        }

        @media #{$lbp} {
            margin-bottom: 100px;
        }

        @media #{$xbp} {
            margin-bottom: 120px;
        }

        @media #{$wbp} {
            margin-bottom: 140px;
        }

        @supports(display: grid) {
            max-width: none;
            display: grid;
            gap: 30px;

            @media #{$sbp} {
                grid-template-columns: 1fr 1fr;
            }

            @media #{$mbp} {
                grid-template-columns: 1fr 1fr 1fr;
                gap: 30px;
            }

            @media #{$lbp} {
                gap: 40px;
            }

            @media #{$wbp} {
                gap: 60px;
            }
        }
    }

    li {
        max-width: 300px;
        display: inline-block;

        @supports(display: grid) {
            max-width: none;
            display: block;
            margin: 0;
        }
    }

    img {
        display: block;
        width: 100%;
        margin-bottom: 10px;

        @media #{$lbp} {
            margin-bottom: 15px;
        }
    }

    h2, h3 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: $light;
        margin-bottom: 0;
        text-align: center;

    }

    h2 {
        font-size: 13px;
        margin-bottom: 4px;

        @media #{$lbp} {
            font-size: 15px;
            margin-bottom: 3px;
        }

        @media #{$xbp} {
            font-size: 16px;
        }
    }

    h3 {
        color: $colorPrimary;
        font-size: 12px;

        @media #{$lbp} {
            font-size: 13px;
        }

        @media #{$xbp} {
            font-size: 14px;
        }
    }
}

.eng-team {
    text-align: center;
    padding-bottom: 20px;

    h2 {
        margin-bottom: 12px;
        font-size: 16px;

        @media #{$mbp} {
            font-size: 20px;
        }

        @media #{$xbp} {
            font-size: 24px;
        }
    }

    br {
        @media #{$sbp} {
            display: none;
        }
    }

    p {
        margin: 0 auto 24px;
        font-size: 15px;
        max-width: 290px;

        @media #{$xsbp} {
            max-width: 315px;
            margin-bottom: 30px;
        }

        @media #{$sbp} {
            max-width: 400px;
        }

        @media #{$mbp} {
            margin-bottom: 40px;
            max-width: 560px;
        }

        @media #{$xbp} {
            margin-bottom: 60px;
        }
    }

    span {
        color: white;
        font-weight: $semibold;
    }

    img {
        display: block;
        max-width: 800px;
        margin: 0 auto;

        @media #{$wbp} {
            max-width: 1000px;
        }
    }
}