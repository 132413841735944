.fire-1 {
    @include margin;

    @include minmax(950px, 1139px) {
        padding: 100px 0px;
    }

    @media #{$lbp} {
        background: #000 url('/img/fire-1.jpg') no-repeat right center / contain;
    }

    >div {
        margin-bottom: 0;
    }

    p {
        @media #{$lbp} {
            max-width: 60%;
        }
    }
}

.fire-3 {
    @include margin;

    @include minmax(950px, 1139px) {
        padding: 100px 0px;
    }

    @media #{$lbp} {
        background: url('/img/24-7.jpg') no-repeat left -50px center / 750px;
    }

    @media #{$xbp} {
    }

    @media #{$wbp} {
        background: url('/img/24-7.jpg') no-repeat left -50px center / 800px;
    }

    >div {
        margin-bottom: 0;

        @media #{$lbp} {
            margin-left: auto;
            max-width: 600px;
        }

        @media #{$xbp} {
            max-width: 700px;
        }
    }

    img {
        @media #{$lbp} {
            display: none;
        }
    }
}