.home-hero {
    background: url('/img/home-hero.jpg') no-repeat center center / cover;
    animation: fadeIn 0.6s ease 0.4s both;

    @media #{$mbp} {
        padding: 80px;
    }

    @media #{$lbp} {
        padding: 100px 0;
    }

    @media #{$xbp} {
        padding: 150px 0;
    }

    @media #{$wbp} {
        padding: 180px 0;
    }

    >div {
        background: rgba($black, 0.8);
        text-align: center;
        padding: 30px 15px;
        animation: fadeIn 0.6s ease 0.7s both;

        @media #{$sbp} {
            padding: 50px;
        }

        @media #{$mbp} {
            background: rgba($black, 0.7);
            backdrop-filter: blur(12px);
            max-width: 500px;
            margin: auto;
            padding: 40px;
        }

        @media #{$lbp} {
            max-width: 600px;
            animation: fadeUp 0.6s ease 0.7s both;
        }

        @media #{$xbp} {
            max-width: 770px;
            padding: 80px 60px;
        }
    }

    h1 {
        margin-bottom: 15px;

        @media #{$wbp} {
            margin-bottom: 20px;
        }
    }

    p {
        margin: 0 auto 20px auto;
        max-width: 300px;

        @media #{$mbp} {
            max-width: 450px;
        }

        @media #{$xbp} {
            max-width: none;
        }

        @media #{$wbp} {
            margin-bottom: 30px;
        }
    }
}

.home-section-1,
.home-section-2 {
    >div>div {
        @media #{$mbp} {
            width: 400px;
        }

        @media #{$lbp} {
            width: 475px;
        }

        @media #{$xbp} {
            width: 550px;
        }
    }
}

.home-section-1 {
    @media #{$mbp} {
        background: url('/img/home-bg-1.jpg') no-repeat left center / 400px;
        padding: 50px 0;
    }

    @media #{$lbp} {
        background: url('/img/home-bg-1.jpg') no-repeat left center / 500px;
    }

    @media #{$xbp} {
        background: url('/img/home-bg-1.jpg') no-repeat left center / 750px;
        padding: 80px 0;
    }

    @media #{$wbp} {
        background: url('/img/home-bg-1.jpg') no-repeat left top -200px / 950px;
        padding: 150px 0;
    }

    >div>div {
        padding: 45px 0 40px;
        max-width: 500px;

        @media #{$sbp} {
            padding: 65px 0 60px;
        }

        @media #{$mbp} {
            padding: 80px 0 110px;
            margin-left: 270px;
        }

        @media #{$lbp} {
            margin-left: 350px;
            padding: 85px 0 115px;
        }

        @media #{$xbp} {
            padding: 80px 0 220px;
            margin-left: 450px;
        }

        @media #{$wbp} {
            margin-left: auto;
            padding: 100px 0 230px;
            transform: translateX(-60px);
        }
    }
}

.home-section-2 {
    margin-bottom: 40px;


    @media #{$sbp} {
        margin-bottom: 60px;
    }

    @media #{$mbp} {
        background: url('/img/home-bg-2.jpg') no-repeat right center / 600px;
        margin-bottom: 0;
        padding: 160px 0 100px;
    }

    @media #{$lbp} {
        background: url('/img/home-bg-2.jpg') no-repeat right center / 650px;
        padding: 190px 0 130px;
    }

    @media #{$xbp} {
        background: url('/img/home-bg-2.jpg') no-repeat right -150px center / 900px;
        padding: 300px 0 150px;
    }

    @media #{$wbp} {
        background: url('/img/home-bg-2.jpg') no-repeat right -300px center / 1250px;
        padding: 360px 0 200px;
    }
}

.home-services {
    margin-bottom: 60px;

    @media #{$mbp} {
        margin: -50px 0;
    }

    @media #{$xbp} {
        margin: -150px auto;
    }
}

.home-projects {
    >div:first-child {
        @include margin;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 600px;
    }
}

.seamless {
    @include margin;
    background: url('/img/seamless.jpg') no-repeat center center / cover;
    display: flex;
    justify-content: center;
    padding: 50px 10px;

    @media #{$sbp} {
        padding: 60px 10px;
    }

    @media #{$mbp} {
        padding: 80px 10px;
    }

    @media #{$lbp} {
        padding: 100px 10px;
    }

    @media #{$xbp} {
        padding: 150px 0;
    }

    @media #{$wbp} {
        padding: 220px 0;
    }

    h3 {
        margin: 0;
        padding: 10px 20px;
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.40);

        @supports(backdrop-filter: blur(14px)) {
            background: rgba($white, 0.1);
            backdrop-filter: blur(14px);
        }
    }
}