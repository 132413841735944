@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-up {
    opacity: 0;
    transition: all 0.6s ease-out;
    transform: translateY(40px);
}

.faded-up {
    opacity: 1;
    transform: translateY(0);
}

.scrollFadeInRight {
    opacity: 0;
    transition: all 2s ease;
    transform: translateX(-40px);
}

@keyframes out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.will-fade {
    animation: out 0s ease both;
}

.will-fade.fade-in {
    opacity: 1;
    animation: fadeIn 0.8s ease both;
}

.will-fade.fade-up {
    opacity: 1;
    animation: fadeUp 0.8s ease both;
}

@keyframes border {
    to {}
}