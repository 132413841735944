.hc-intro {
    background: url('/img/home-hero.jpg') no-repeat center center / cover;
    text-align: center;
    margin-bottom: 40px;

    @media #{$sbp} {
        margin-bottom: 50px;
        margin-top: 80px;
    }

    @media #{$mbp} {
        margin-bottom: 60px;
    }

    @media #{$lbp} {
        margin-bottom: 80px;
    }

    @media #{$xbp} {
        margin-bottom: 100px;
    }

    @media #{$wbp} {
        margin-bottom: 120px;
    }

    > div {
        background-image: linear-gradient(to bottom, $black,  rgba($black, 90%) 50%, rgba($black, 0));
        padding: 0 25px 200px;

        @media #{$xbp} {
            padding: 0 25px 300px;
        }

        @media #{$wbp} {
            padding: 30px 25px 400px;
        }
    }

    h2, p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        @media #{$lbp} {
            max-width: 700px;
        }
    }
}

.hc-1 {
    margin-bottom: 40px;

    @media #{$sbp} {
        margin-bottom: 50px;
    }

    @media #{$mbp} {
        margin-bottom: 60px;
    }

    @media #{$lbp} {
        background: url('/img/hc-1-bg.jpg') no-repeat bottom center / 100%;
        padding-bottom: 300px;
        margin-bottom: 80px;
    }

    @media #{$xbp} {
        margin-bottom: 100px;
        padding-bottom: 400px;
    }

    @media #{$wbp} {
        padding-bottom: 450px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    > div {
        padding: 0 25px;
        max-width: 1200px;
        margin: 0 auto;

        @media #{$xsbp} {
            padding: 0 30px;
        }

        @media #{$sbp} {
            padding: 0 50px;
        }

        @media #{$mbp} {
            padding: 0 60px;
        }

        @media #{$lbp} {
            display: flex;
            position: relative;
            padding: 0 80px;
        }

        > div {
            margin-bottom: 40px;

            @media #{$sbp} {
                margin-bottom: 50px;
            }

            @media #{$mbp} {
                margin-bottom: 60px;
            }

            @media #{$lbp} {
                flex-basis: 50%;
                margin-bottom: 0;
            }

            &:first-child {
                @media #{$lbp} {
                    margin: -160px 50px 0 0;
                }

                @media #{$xbp} {
                    margin: -230px 70px 0 0;
                }

                @media #{$wbp} {
                    margin: -220px 110px 0 0;
                    flex-basis: 60%;
                }
            }
        }
    }

    > img {
        height: auto;
        width: 100%;

        @media #{$lbp} {
            display: none;
        }
    }
}

.hc-2 {
    margin-bottom: 40px;

    @media #{$sbp} {
        margin-bottom: 50px;
    }

    @media #{$mbp} {
        margin-bottom: 60px;
    }

    @media #{$lbp} {
        margin-bottom: 80px;
    }

    @media #{$xbp} {
        background: url('/img/hc-2-bg.jpg') no-repeat left -50px center / contain;
        margin-bottom: 90px;
    }

    @media #{$wbp} {
        margin: 0 auto 120px;
        max-width: 1300px;
    }

    >div {
        padding: 0 25px;
        margin-bottom: 40px;

        @media #{$xsbp} {
            padding: 0 30px;
        }

        @media #{$sbp} {
            padding: 0 50px;
            margin-bottom: 50px;
        }

        @media #{$mbp} {
            padding: 0 60px;
            margin-bottom: 60px;
        }

        @media #{$lbp} {
            padding: 0 80px;
            margin-bottom: 80px;
        }

        @media #{$xbp} {
            margin: 0 auto;
            max-width: 1260px;
        }

        >div {
            @media #{$xbp} {
                background: url('/img/dash-bg.svg') repeat-x bottom center / 18px;
                padding-bottom: 90px;
            }

            @media #{$wbp} {
                padding-bottom: 120px;
            }
        }

        h2 {
            @media #{$xbp} {
                text-align: center;
                margin-bottom: 70px;
            }

            @media #{$wbp} {
                margin-bottom: 90px;
            }
        }

        p {
            @media #{$xbp} {
                max-width: 500px;
                margin-left: auto;
            }
        }
    }

    > img {
        height: auto;
        width: 100%;

        @media #{$xbp} {
            display: none;
        }
    }
}

.hc-3 {
    @media #{$lbp} {
        padding: 0 70px;
        background-image: url('/img/speakers.jpg'),
                          url('/img/hc-3bg.jpg'),
                          url('/img/projector-bg.jpg'),
                          url('/img/hc-4bg.jpg');
        background-repeat: no-repeat;
        background-position: top right 60px,
                             top 850px left,
                             top 2150px right -100px,
                             bottom 60px left -50px;
        background-size: 400px,
                         800px,
                         800px,
                         550px;
        max-width: 950px;
        margin: auto;

        @media #{$xbp} {
            max-width: 1140px;
            background-position: top right 80px,
                                top 750px left,
                                top 2050px right -80px,
                                bottom left -60px;
            background-size: 500px,
                         900px,
                         900px,
                         650px;
        }

        @media #{$wbp} {
            padding: 0 100px;
            max-width: 1300px;
            background-position: top right 120px,
                             top 740px left,
                             top 2100px right,
                             bottom left -60px;
            background-size: 550px,
                             1100px,
                             950px,
                             750px;
        }
    }

    .heading {
        display: flex;
        text-align: center;
        justify-content: center;
        margin-bottom: 40px;

        @media #{$sbp} {
            margin-bottom: 50px;
        }

        @media #{$mbp} {
            margin-bottom: 60px;
        }

        @media #{$lbp} {
            margin-bottom: 70px;
        }

        @media #{$wbp} {
            margin-bottom: 80px;
        }

        img {
            margin: 0 5px 0 0;
            width: 15px;

            @media #{$xsbp} {
                width: 18px;
                margin-right: 7px;
            }

            @media #{$sbp} {
                width: 24px;
            }

            @media #{$mbp} {
                display: block;
                width: 35px;
                margin-right: 15px;
            }

            @media #{$wbp} {
                width: 37px;
                margin-right: 17px;
            }
        }
    }

    >div {
        margin-bottom: 40px;

        @media #{$sbp} {
            margin-bottom: 50px;
        }
    }

    img {
        height: auto;
        display: block;
        margin-bottom: 45px;

        @media #{$sbp} {
            margin-bottom: 55px;
        }

        @media #{$mbp} {
            margin-bottom: 65px;
        }

        @media #{$lbp} {
            display: none;
        }
    }
}

.feature-group {
    @media #{$lbp} {
        display: flex;
    }

    @media #{$xbp} {
        margin-bottom: 60px;
    }

    @media #{$wbp} {
        margin-bottom: 80px;
    }

    &:first-of-type {

        @media #{$lbp} {
            margin-bottom: 180px;
        }

        .feature:last-child {
            @media #{$lbp} {
                padding-top: 350px;
            }

            @media #{$xbp} {
                padding-top: 450px;
            }

            @media #{$wbp} {
                padding-top: 500px;
            }
        }
    }

    &:last-child {
        .feature {
            @media #{$lbp} {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

.feature {
    margin-bottom: 40px;
    padding: 0 25px;

    @media #{$xsbp} {
        padding: 0 30px;
    }

    @media #{$sbp} {
        padding: 0 50px;
        margin-bottom: 50px;
    }

    @media #{$mbp} {
        padding: 0 60px;
        margin-bottom: 60px;
    }

    @media #{$lbp} {
        flex-basis: 50%;
        padding: 0;
        margin-right: 60px;
    }

    @media #{$xbp} {
        margin-right: 80px;
    }

    @media #{$wbp} {
        margin-right: 100px;
    }
}

.hc-support {
    margin-bottom: 40px;
    background: black;

    @media #{$xsbp} {
        margin-bottom: 50px;
    }

    @media #{$sbp} {
        margin-bottom: 60px;
    }

    @media #{$mbp} {
        margin-bottom: 70px;
        background: black url('/img/hc-support-bg.jpg') no-repeat center right / contain;
        padding: 80px 60px;
    }

    @media #{$lbp} {
        margin-bottom: 80px;
        padding: 80px 70px;
    }

    @media #{$xbp} {
        margin-bottom: 100px;
        padding: 100px 80px;
    }

    @media #{$wbp} {
        margin-bottom: 120px;
        padding: 120px 80px;
    }

    >div {
        padding: 40px 25px;
        max-width: 1240px;
        margin: auto;

        @media #{$xsbp} {
            padding: 40px 30px;
        }

        @media #{$sbp} {
            padding: 50px;
        }

        @media #{$mbp} {
            padding: 0;
        }
    }

    p {
        max-width: 600px;
    }

    img {
        flex-basis: fill;
        width: 100%;

        @media #{$mbp} {
            display: none;
        }
    }
}