*,
body,
html {
    box-sizing: border-box;
    font-family: $fontStack;
}

body {
    color: $white;
    background: $black;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

a {
    transition: all 0.3s ease;
    color: $white;

    &:hover {
    }
}

hr {
    width: 30px;
    border-color: $colorPrimary;
    margin: 0 0 20px 0;

    @media #{$xbp} {
        width: 50px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontStack;
    line-height: 1.3;
    font-size: 16px;
    margin: 0 0 15px 0;
    padding: 0;
}

h1,
.h1 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: $light;

    @media #{$wbp} {
        font-size: 55px;
    }
}

img {
    max-width: 100%;
}

.btn {
    font-family: $fontSerif;
    font-style: italic;
    color: $colorPrimary;
    font-size: 15px;
    transition: all 0.3s ease;
    display: inline-block;

    @media #{$lbp} {
        font-size: 17px;
    }

    img {
        margin-left: 5px;
        transition: all 0.3s ease;
    }

    &:hover {
        color: $colorPrimary;
        filter: brightness(1.1);
        text-shadow: 0 0 15px $colorPrimary;

        img {
            transform: translateX(5px);
        }
    }

    &:active,
    &:focus {
        color: $colorPrimary;
    }
}

.btn-alt {
    @extend .btn;
    border: 1px solid $colorPrimary;
    padding: 12px 20px;
    min-width: 140px;
    text-align: center;

    @media #{$mbp} {
        min-width: 160px;
        padding: 15px 30px;
    }
}

p + .btn,
p + .btn-wrapper {
    margin-top: 5px;

    @media #{$mbp} {
        margin-top: 25px;
    }
}

button {
    border: none;
    background: none;
    cursor: pointer;

    &:active,
    &:focus {
        outline: none;
    }
}

.contain {
    @include contain;
}

body {
    &.nav-open,
    &.modal-open {
        overflow: hidden;

        #overlay {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 6000;
            background-color: rgba(0, 0, 0, 0.8);
            animation: fadeIn 0.5s ease;
        }
    }
}

// components
.preview-list {
    @include contain;
    @include margin;
    margin-bottom: 50px;

    @include minmax(500px, 767px) {
        margin-bottom: 70px;
    }

    @media #{$mbp} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
    }

    @media #{$lbp} {
        grid-gap: 50px;
    }

    @media #{$xbp} {
        grid-gap: 60px;
    }
}

.preview {
    position: relative;
    margin-bottom: 20px;
    display: block;
    overflow: hidden;

    @media #{$xsbp} {
        margin-bottom: 30px;
    }

    @media #{$mbp} {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        background-image: linear-gradient(45deg, black, rgba(black, 0.1) 70%);
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    picture {
        display: block;
    }

    img {
        transition: all 0.3s ease-out;
        transform: scale(1.1);
    }

    &:hover {
        img {
            transform: scale(1.1) translateX(-10px);
        }
    }

    h2,
    h3 {
        position: absolute;
        bottom: 10px;
        left: 10px;
        margin: 0;
        z-index: 20;

        @media #{$sbp} {
            left: 15px;
        }

        @media #{$mbp} {
            bottom: 20px;
            left: 10px;
            min-width: 400px;
            transform: rotate(-90deg) translateY(100%);
            transform-origin: left bottom;
            font-size: 15px;
        }

        @media #{$lbp} {
            font-size: 16px;
        }

        @media #{$xbp} {
            bottom: 30px;
            left: 20px;
            font-size: 18px;
        }

        @media #{$wbp} {
            font-size: 20px;
        }
    }
}
