.certified {
    @include margin;
    @include contain;
    background: #000;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    @media #{$xsbp} {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media #{$sbp} {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @media #{$mbp} {
        padding-top: 80px;
        padding-bottom: 80px;
        margin-bottom: 80px;
    }

    @media #{$lbp} {
        background: none;;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 100px;
    }

    @media #{$xbp} {
        margin-bottom: 140px;
    }

    p {
        max-width: 550px;
        margin: 0 auto 20px;

        @media #{$sbp} {
            margin-bottom: 40px;
        }

        @media #{$lbp} {
            max-width: 600px;
        }

        @media #{$xbp} {
            max-width: 780px;
            margin-bottom: 50px;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $colorPrimary;
            }
        }
    }
}

.cert-logos {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;

    img {
        display: block;
        margin: 0 25px;
        max-width: 100px;
        height: auto;
        max-height: 70px;

        @media #{$lbp} {
            max-width: 70px;
            max-height: 80px;
            margin: 0 25px;
        }

        @media #{$xbp} {
            max-width: 100px;
            max-height: 120px;
            margin: 0 35px;
        }
    }

    > div {
        &:nth-child(2) {
            img {
                @media #{$xbp} {
                    // height: 160px;
                }
            }
        }
    }
}