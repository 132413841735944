.text-overlay {
    @media #{$xbp} {
        padding: 120px 20px;
    }

    @media #{$wbp} {
        max-width: 1440px;
        margin: auto;
        padding: 150px 60px;
    }

    @include mq(1400px) {
        padding: 150px 80px;
    }

    >div {
        padding: 0 25px;
        margin-bottom: 40px;

        @media #{$xsbp} {
            padding: 0 30px;
        }

        @media #{$sbp} {
            padding: 0 50px;
            margin-bottom: 70px;
        }

        @media #{$mbp} {
            padding: 0 60px;
            margin-bottom: 80px;
        }

        @media #{$lbp} {
            padding: 0 80px;
            margin-bottom: 90px;
        }

        @media #{$xbp} {
            margin: 0;
            max-width: 1260px;
        }

        >div {
            @media #{$xbp} {
                background: url('/img/dash-bg.svg') repeat-x bottom center / 18px;
                padding-bottom: 90px;
            }

            @media #{$wbp} {
                padding-bottom: 120px;
            }
        }

        p {
            @media #{$xbp} {
                max-width: 540px;
                margin-bottom: 20px;
            }

            @media #{$wbp} {
                max-width: 600px;
            }

            a {
                text-decoration: underline;

                &:hover {
                    color: $colorPrimary;
                }
            }
        }
    }

    > img {
        height: auto;
        width: 100%;
        margin-bottom: 40px;

        @media #{$xbp} {
            display: none;
        }

        @media #{$sbp} {
            margin-bottom: 60px;
        }

        @media #{$mbp} {
            margin-bottom: 70px;
        }

        @media #{$lbp} {
            margin-bottom: 80px;
        }
    }
}
