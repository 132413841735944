.contact {
    @include margin;
    @include paddingTop;
    max-width: 1200px;

    @media #{$lbp} {
        display: flex;
    }

    h1 {
        @media #{$lbp} {
            margin-bottom: 20px;
        }
    }

    > div {
        &:first-child {
            margin-bottom: 50px;
            flex-basis: 400px;

            @media #{$sbp} {
                margin-bottom: 30px;
            }
        }

        &:last-child {
            flex-grow: 1;

            @media #{$lbp} {
                margin-left: 60px;
            }

            @media #{$wbp} {
                margin-left: 100px;
            }
        }
    }

    form {
        background: #000;
        padding: 20px;

        @media #{$sbp} {
            padding: 40px;
        }

        @media #{$lbp} {
            padding: 60px;
        }

        @media #{$wbp} {
            padding: 80px;
        }
    }

    & + img {
        width: 100%;
        display: block;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;

        @include mq(1600px) {
            margin: 0 auto 120px auto;
            max-width: 1500px;
        }
    }
}

.contact-info {
    p {
        max-width: 400px;
    }

    address {
        max-width: 300px;
    }

    > div > div {
        @media #{$sbp} {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-gap: 40px;
        }

        @media #{$xbp} {
            display: block;
        }

        > div {
            margin-bottom: 30px;

            @media #{$xbp} {
                display: flex;
            }
        }
    }

    h2,
    span,
    address {
        @media #{$xbp} {
            line-height: 1.6;
        }
    }

    h2 {
        color: $colorPrimary;
        margin-bottom: 5px;

        @media #{$lbp} {
            font-size: 16px;
        }

        @media #{$xbp} {
            flex-basis: 120px;
            flex-shrink: 0;
            letter-spacing: 4px;
            margin-bottom: 0;
        }
    }
}

.contact-info-text {
    border-bottom: $border;
    margin-bottom: 40px;
    padding-bottom: 40px;

    p {
        margin-bottom: 10px;
    }

    a {
        margin: 15px 0 0 0;
    }
}

.coms {
    display: grid;
    grid-template-columns: auto auto;

    @media #{$sbp} {
    }
}

.socials {
    padding-top: 5px;
    display: flex;

    @media #{$xbp} {
        padding-top: 0;
    }

    a {
        margin-right: 10px;
        display: block;
        width: 25px;

        &:hover {
            opacity: 0.6;
        }

        img {
            width: 100%;
        }
    }
}

.maplink {
    display: inline-block;
    font-size: 14px;
    color: $colorPrimary;
    padding: 7px 0 3px 0;
    border-bottom: 1px solid $colorPrimary;
}
