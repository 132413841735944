.about-header {
    background: url("/img/about-hero-s.jpg") no-repeat center center / cover;

    @media #{$sbp} {
        background: url("/img/about-hero-l.jpg") no-repeat center center / cover;
    }
}

.about-intro {
    margin-bottom: 60px;

    @media #{$sbp} {
        margin-bottom: 70px;
    }

    @media #{$mbp} {
        margin-bottom: 90px;
    }

    @media #{$lbp} {
        padding-top: 20px;
        margin: 0 0 100px;
    }

    @media #{$xbp} {
        padding-top: 0;
    }

    @media #{$wbp} {
        display: flex;
        align-items: flex-end;
        margin: -200px auto 120px;
    }
}

.about-main-intro {
    background: black;
    margin: -40px -20px 40px;
    padding: 40px 20px;

    @media #{$xsbp} {
        margin: -40px -30px 40px;
        padding: 40px 30px;
    }

    @media #{$sbp} {
        margin: -50px -50px 50px;
        padding: 50px;
    }

    @media #{$mbp} {
        margin: -70px -60px 70px;
        padding: 70px 60px;
    }

    @media #{$lbp} {
        position: relative;
        z-index: 10;
        margin: -140px 0 80px 0;
        padding: 50px 60px;
        border-top: 7px solid $colorPrimary;
    }

    @media #{$wbp} {
        margin: 0 80px 0 0;
        padding: 70px;
        flex-basis: calc(50% - 40px);
        flex-shrink: 0;
    }

    @include mq(1400px) {
        margin-right: 100px;
        flex-basis: calc(50% - 50px);
        padding: 80px;
    }
}

.about-services {
    margin-bottom: -50px;
    position: relative;
    z-index: 10;

    @media #{$sbp} {
        margin-bottom: -60px;
    }

    @media #{$mbp} {
        display: flex;
    }

    @media #{$wbp} {
        margin-bottom: -80px;
    }

    @media #{$wbp} {
        margin-bottom: -140px;
    }

    .service-list-stacked {
        margin-bottom: 70px;
        flex-basis: 50%;

        &:last-child {
            margin-bottom: 0;

            @media #{$mbp} {
                margin-left: 70px;
            }

            @include mq(1400px) {
                margin-left: 100px;
            }
        }

        @media #{$mbp} {
            margin-bottom: 0;
        }
    }
}

.about-electrical {
    background: url("/img/about-s.jpg") no-repeat top center / 100%;
    padding: 250px 0 30px;
    margin: auto;

    @media #{$xsbp} {
        padding-bottom: 45px;
    }

    @media #{$sbp} {
        padding: 350px 0 50px;
    }

    @media #{$mbp} {
        padding-top: 450px;
        padding-bottom: 70px;
    }

    @media #{$lbp} {
        padding-bottom: 80px;
        padding-top: 600px;
    }

    @media #{$xbp} {
        padding-top: 700px;
        background: url("/img/about-l.jpg") no-repeat top center / 100%;
    }

    @media #{$wbp} {
        padding-top: 800px;
        padding-bottom: 120px;
    }

    @include mq(1400px) {
        padding-top: 850px;
    }

    @include mq(1600px) {
        max-width: 1500px;
    }

    .contain {
        @media #{$lbp} {
            display: flex;
        }
    }

    .service-list-stacked {
        margin-top: 70px;

        @media #{$lbp} {
            flex-basis: calc(50% - 30px);
            flex-shrink: 0;
            margin: 0 0 0 60px;
        }

        @media #{$xbp} {
            flex-basis: calc(50% - 45px);
            margin: 0 0 0 90px;
        }

        @media #{$wbp} {
            flex-basis: calc(50% - 60px);
            margin: 0 0 0 120px;
        }
    }
}

.about-latest-projects {
    padding: 40px 0 0 0;
    background: linear-gradient(to bottom, #000, transparent);

    @media #{$xsbp} {
        padding: 50px 0 0 0;
    }

    @media #{$sbp} {
        padding: 60px 0 0 0;
    }

    @media #{$mbp} {
        padding: 70px 0 10px 0;
    }

    @media #{$lbp} {
        padding-top: 90px;
    }

    @media #{$xbp} {
        padding-bottom: 0;
    }

    @media #{$wbp} {
        padding: 120px 0 0 0;
    }

    > div {
        &:first-child {
            text-align: center;
            padding: 0 25px;
            margin: 0 auto 40px;
            max-width: 1000px;

            @media #{$sbp} {
                padding: 0 50px;
                margin: 0 auto 60px;
            }

            @media #{$mbp} {
                padding: 0 70px;
            }

            @media #{$wbp} {
                margin: 0 auto 120px;
            }
        }
    }
}
