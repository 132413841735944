form {
    position: relative;

    button {
        @extend .btn;
        display: block;
        position: relative;
        margin: 15px auto 25px auto;
        background: url('/img/icons/arrow.svg') no-repeat right center / 25px;
        text-align: left;
        width: 70px;

        @media #{$sbp} {
            margin: 30px auto 25px;
        }

        @media #{$lbp} {
            margin-bottom: 10px;
        }

        @media #{$wbp} {
            margin-bottom: 0;
        }
    }
}

.field,
.fui-field-container {
    margin-bottom: 7px;
    animation: fadeIn 0.5s ease 0.3s both;
    position: relative;
    padding-bottom: 25px;

    @media #{$sbp} {
        padding-bottom: 25px;
    }
}

.textarea-field {
    .fui-field-container {
        padding-bottom: 5px;
    }
}

.fui-error-message {
    position: absolute;
    color: $error;
    bottom: 2px;
    left: 0;
    font-size: 12px;

    @media #{$xsbp} {
        font-size: 13px;
    }

    @media #{$sbp} {
        font-size: 14px;
    }
}

textarea {
    height: 130px;
}

input,
textarea {
    @include placeholders($darkgrey, 14px);
    border-radius: 0;
    background: none;
    color: $white;
    padding: 12px 0;
    border: 0;
    border-bottom: 1px solid rgba($white, 0.3);

    @media #{$lbp} {
        font-size: 14px;
    }
}

.fui-required {
    position: relative;
    left: -5px;

    @media #{$lbp} {
        left: -10px;
    }
}

label {
    cursor: pointer;
    text-transform: uppercase;
    color: $colorPrimary;
    font-size: 14px;
    margin-bottom: 0;
    letter-spacing: 2px;
    // padding: 0 20px;

    @media #{$lbp} {
        padding: 0;
        font-size: 16px;
        letter-spacing: 4px;
    }
}

.error {
    color: $colorPrimary;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 20px;

    @media #{$lbp} {
        left: 0;
    }
}

.general-form {
    label:not(.btn) {
        color: $colorPrimary;
        margin-bottom: 10px;
        padding: 0;

        @media #{$mbp} {
            margin-bottom: 15px;
        }
    }

    .form-error {
        left: 0;
    }

    input,
    .file-input-wrapper {
        @include boxShadow;
        border: 0;
        background: $white;
        border-radius: 4px;
    }

    input {
        padding: 12px;

        @media #{$mbp} {
            padding: 17px;
        }

        @media #{$lbp} {
            padding: 20px;
        }
    }
}

input[type="checkbox"] {
    display: none;
}

.checkbox-wrapper {
    position: relative;
    max-width: 260px;

    @media #{$sbp} {
        max-width: none;
    }

    .fui-field-container {
        margin-bottom: 0;
        padding-bottom: 35px;

        @media #{$sbp} {
            padding-bottom: 15px;
        }
    }

    .fui-error-message {
        padding-left: 30px;
        top: 50px;
        left: 0;
        right: -20px;

        @media #{$xsbp} {
            right: -40px;
        }

        @media #{$sbp} {
            top: 25px;
        }
    }

    label {
        text-transform: none;
        font-size: 14px;
        color: $white;
        line-height: 1.7;
        font-family: $fontStack;
        padding-left: 30px;
        margin-bottom: 0;

        @media #{$mbp} {
            display: flex;
            padding-left: 0;
        }

        @media #{$lbp} {
            display: inline;
            padding-left: 30px;
        }

        @media #{$xbp} {
            display: flex;
            padding-left: 0;
        }

        a {
            color: $colorPrimary;
            text-decoration: underline;
            margin-left: 3px;

            &:hover {
                opacity: 0.7;
            }
        }

        &:before {
            content: " ";
            display: inline-block;
            height: 20px;
            width: 20px;
            flex: 0 0 20px;
            background: url('/img/icons/unchecked.svg') no-repeat center center / 20px;
            margin-right: 10px;
            left: 2px;
            position: absolute;

            @media #{$mbp} {
                position: relative;
            }

            @media #{$lbp} {
                position: absolute;
            }

            @media #{$xbp} {
                position: relative;
            }
        }
    }
}

input[type="checkbox"]:checked+label:before {
    background: url('/img/icons/checked.svg') no-repeat center center / 20px;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    opacity: 0;
}

.loader {
    display: inline-block;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
}

.loader:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loading 1.2s linear infinite;
    transform-origin: center center;
}

form button.sent {
    transition: all 0.3s ease;
    background: #72db3e;
    // background-position: right 25px center;
}

.contact-form {
    @include contain;
    @include margin;

    @media #{$xbp} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    h2 {
        @media #{$xbp} {
            margin-bottom: 15px;
        }
    }

    h3 {
        font-size: 28px;

        @media #{$wbp} {
            font-size: 32px;
        }
    }

    >div {
        &:first-child {
            margin-bottom: 40px;

            @media #{$lbp} {
                margin-bottom: 50px;
            }

            @media #{$xbp} {
                flex: 0 0 30%;
                margin-right: 80px;
                margin-bottom: 0;
            }

            @media #{$wbp} {
                margin-right: 120px;
            }
        }

        &:last-child {
            flex: 1;

            form {
                // margin-top: 20px;
            }
        }
    }

    .subheading {
        color: $colorPrimary;
    }

    p {
        @media #{$wbp} {
            // font-size: 26px;
        }
    }
}

.fui-alert-success {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -20px;
    font-size: 14px;

    @media #{$sbp} {
        margin-top: -10px;
    }

    @media #{$mbp} {
        font-size: 16px;
        margin-top: -15px;
    }
}

.fui-alert:not(.fui-alert-success) {
    position: absolute;
    bottom: 65px;
    text-align: center;
    width: 100%;

    @media #{$sbp} {
        bottom: 12px;
        left: 140px;
        font-size: 14px;
        text-align: left;
        width: auto;
    }

    @media #{$mbp} {
        font-size: 16px;
    }

    @media #{$xbp} {
        // left: 160px;
        bottom: 14px;
    }

    @media #{$wbp} {
        left: 180px;
    }
}