.alarms-1 {
    @include margin;

    @include minmax(950px, 1139px) {
        padding: 120px 0px;
        margin-bottom: 40px;
    }

    @media #{$lbp} {
        background: url('/img/alarms-1.jpg') no-repeat right bottom / contain
    }

    @media #{$xbp} {
        margin-bottom: 40px;
        padding: 180px 0px 160px;
    }

    @media #{$wbp} {
        background: url('/img/alarms-1.jpg') no-repeat right -60px bottom / contain;
        padding: 280px 0px 220px;
        margin-top: -100px;
    }

    >div {
        margin-bottom: 0;

        @media #{$xbp} {
            max-width: 650px;
        }

        @media #{$wbp} {
            margin-left: 80px;
        }
    }

    p {
        @media #{$lbp} {
            max-width: 450px;
        }


    }
}

.alarms-2 {
    @media #{$sbp} {
        margin-bottom: 50px;
    }

    @media #{$lbp} {
        margin-bottom: 80px;
        background: url(/img/security-2-bg.jpg) no-repeat left -80px center / 750px;
        padding: 100px 0;
    }

    @media #{$xbp} {
        margin-bottom: 110px;
        padding: 130px 0;
    }

    @media #{$wbp} {
        margin-bottom: 130px;
        padding: 150px 0;
    }

    >div {
        @media #{$sbp} {
            margin-bottom: 0;
        }

        @media #{$lbp} {
            margin-left: auto;
            max-width: 650px;
        }

        @media #{$xbp} {
            max-width: 650px;
            margin-right: 40px;
        }

        @media #{$wbp} {
            max-width: 720px;
            margin-right: 70px;
        }

        p {
            @media #{$xbp} {
                max-width: none;
            };
        }
    }

    img {
        @media #{$lbp} {
            display: none;
        }
    }
}

.alarms-4 {
    @include margin;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    position: relative;

    img {
        width: 100%;
        margin-bottom: 40px;

        @media #{$sbp} {
            margin-bottom: 50px;
        }

        @media #{$mbp} {
            margin-bottom: 70px;
        }

        @media #{$lbp} {
            margin-bottom: 0;
        }
    }

    h3, p {
        padding: 0 20px;
        max-width: 1200px;

        @media #{$xsbp} {
            padding: 0 30px;
        }

        @media #{$sbp} {
            padding: 0 50px;
        }

        @media #{$mbp} {
            padding: 0 60px;
        }

        @media #{$lbp} {
            padding: 0;
        }
    }

    .card {
        @media #{$lbp} {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            right: 80px;
        }

        @media #{$xbp} {
            width: 600px;
            right: 120px;

        }
        @media #{$wbp} {
            width: 630px;
            right: 180px;
        }
    }
}