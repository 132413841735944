.cookie-notice {
    display: block;
    position: fixed;
    bottom: 0;
    padding: 20px 0;
    z-index: 500;
    left: 0;
    width: 100%;
    animation: fadeUp 0.4s ease 0.6s both;
    background: #1e1e1e;
    box-shadow: 0 -7px 30px 10 rgba(black, 0.9);

    @media #{$sbp} {
        padding: 30px 0;
    }

    > div {
        @include contain;

        @media #{$lbp} {
            display: flex;
            align-items: center;
        }

        > div {
            flex-grow: 0;

            &:first-child {
                margin-bottom: 15px;

                @media #{$sbp} {
                    margin-bottom: 20px;
                }

                @media #{$lbp} {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }

            &:last-child {
                flex-shrink: 1;
                margin-left: auto;

                @media #{$sbp} {
                    display: flex;
                }

                @media #{$mbp} {
                    display: flex;
                }
            }
        }
    }

    p,
    small {
        max-width: 450px;
        font-size: 13px;

        @media #{$mbp} {
            max-width: 650px;
            font-size: 14px;
        }

        @media #{$lbp} {
            font-size: 13px;
        }

        @media #{$xbp} {
            max-width: 690px;
            font-size: 15px;
        }

        @media #{$wbp} {
            max-width: 750px;
        }
    }

    small {
        color: grey;
        margin-bottom: 15px;
        display: block;

        @include mq(600px) {
            margin-bottom: 0;
        }

        @media #{$mbp} {
            margin-bottom: 15px;
        }

        @media #{$lbp} {
            margin-bottom: 0;
        }
    }

    a {
        color: $colorPrimary;

        &:hover {
            opacity: 0.7;
        }
    }

    p {
        line-height: 1.5;
    }

    button {
        background: rgba(white, 1);
        color: $black;
        font-family: $fontStack;
        font-style: normal;
        padding: 8px 5px;
        font-size: 13px;
        width: 100%;
        transition: all 0.3s ease;

        @media #{$sbp} {
            min-width: 95px;
        }

        @include mq(600px) {
            max-width: 120px;
            padding: 9px 5px;
            font-size: 14px;
        }

        @media #{$xbp} {
            padding: 10px 5px;
            min-width: 110px;
            max-width: 120px;
        }

        &:hover {
            color: $white;
            background: $colorPrimary;
            border-color: $colorPrimary;
        }
    }

    .btn {
        padding: 7px 5px;
        display: block;

        &:first-child {
            margin-bottom: 10px;

            @media #{$sbp} {
                margin-bottom: 0;
                margin-right: 10px;
            }

            @media #{$mbp} {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
    }

    .btn-alt {
        margin-top: 0;
        background: none;
        border: 1px solid white;
        color: $white;
    }
}

.cookie-options {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background: $colorPrimary;
    padding: 20px;
    left: 20px;
    right: 20px;
    margin: auto;
    z-index: 7000;
    max-width: 500px;

    @media #{$xsbp} {
        padding: 30px;
        left: 30px;
        right: 30px;
    }

    @media #{$mbp} {
        padding: 50px;
        max-width: 600px;
    }

    @media #{$lbp} {
        padding: 60px;
        max-width: 700px;
    }

    @media #{$wbp} {
        padding: 80px;
    }

    p {
        line-height: 1.6;
        margin-bottom: 20px;
        font-size: 14px;

        @media #{$mbp} {
            margin-bottom: 30px;
            font-size: 16px;
        }

        a {
            font-weight: $semibold;
            text-decoration: underline;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    > button {
        margin-top: 20px;
        background: white;
        padding: 10px;
        margin-left: auto;
        display: block;
        width: 100px;
        transition: all 0.3s ease;
        border: 2px solid $white;
        font-size: 14px;

        @media #{$mbp} {
            margin-top: 30px;
            padding: 12px;
            width: 120px;
        }

        &:hover {
            background: none;
            color: $white;
        }
    }
}

.cookie-option {
    background: rgba($white, 0.2);
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
        margin-bottom: 10px;
    }

    strong {
        font-size: 14px;
    }

    span {
        font-size: 14px;
    }
}

.lightswitch {
    cursor: pointer;
    border-radius: 11px;
    width: 34px;
    height: 22px;
    position: relative;
    border: none !important;
    overflow: hidden;
    user-select: none;
    transition: background-image linear 100ms;
    background: $darkgrey;

    &.on {
        background: $black;

        .lightswitch-container {
            margin-left: 0;
        }
    }

    .lightswitch-container {
        margin-left: -12px;
        width: 46px;
        position: relative;
        height: 100%;
        transition: all 0.3s ease;

        .handle {
            border-radius: 10px;
            width: 20px;
            height: 20px;
            left: calc(50% - 10px);
            position: absolute;
            top: 1px;
            background-color: #fff;
        }
    }
}

[x-cloak] {
    display: none !important;
}
