.security-main {
    @media #{$lbp} {
        margin-bottom: 90px;
    }

    .heading {
        max-width: none;
    }

    p {
        max-width: 680px;

        @media #{$lbp} {
            max-width: 750px;
        }

        @media #{$wbp} {
            max-width: 790px;
        }
    }
}

#security-1, #security-2 {
    display: flex;
    flex-direction: column-reverse;
}

#security-1 {
    @media #{$xbp} {
        background: url('/img/security-1-bg.jpg') no-repeat right -50px bottom / 750px;
    }

    @media #{$wbp} {
        background: url('/img/security-1-bg.jpg') no-repeat right -50px bottom / 800px;
    }
}

#security-2 {
    @media #{$xbp} {
        background: url('/img/security-2-bg.jpg') no-repeat left -80px center / 750px;
    }

    > div {
        margin-left: auto;
    }
}

.security-3 {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 0 25px;

    @media #{$xsbp} {
        padding: 0 30px;
    }

    @media #{$sbp} {
        margin-bottom: 80px;
        padding: 0 50px;
    }

    @media #{$mbp} {
        padding: 0 60px;
        margin-bottom: 100px;
    }

    @media #{$lbp} {
        padding: 0 80px;
        margin-bottom: 120px;
    }

    @media #{$xbp} {
        max-width: 1400px;
        margin: 0 auto 140px;
    }

    p {
        text-align: center;
        margin-bottom: 40px;

        @media #{$sbp} {
            margin-bottom: 70px;
        }

        @media #{$mbp} {
            margin-bottom: 80px;
        }

        @media #{$lbp} {
            margin-bottom: 90px;
        }

        @media #{$xbp} {
            margin: 120px auto;
            max-width: 750px;
        }
    }

    picture {
        margin: 0 -25px 40px;
        order: -1;

        @media #{$xsbp} {
            margin: 0 -30px 40px;
        }

        @media #{$sbp} {
            margin: 0 -50px 70px;
        }

        @media #{$mbp} {
            margin: 0 -60px 80px;
        }

        @media #{$lbp} {
            margin: 0 -80px 90px;
        }

        @media #{$xbp} {
            order: revert;
            margin: 0 -80px 140px;
        }

        @media #{$wbp} {
        }

        @include mq(1400px) {
            margin: 0 calc(((100vw - 1400px + 160px) / 2)*-1) 140px;
        }

        @include mq(1440px) {
            margin: 0 -100px 140px;
        }

        img {
            display: block;
        }
    }
}