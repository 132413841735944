.error-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    padding: 0 30px;

    h1,
    h2 {
        line-height: 1;
    }
}

.error-404 {
    h1 {
        font-size: 120px;
        font-family: $fontStack;
        font-weight: 200;

        @media #{$sbp} {
            font-size: 130px;
        }

        @media #{$mbp} {
            font-size: 140px;
        }

        @media #{$lbp} {
            font-size: 180px;
        }

        @media #{$wbp} {
            font-size: 200px;
        }
    }

    h2 {
        // font-size: 20px;
        margin-bottom: 30px;

        @media #{$sbp} {
            // font-size: 25px;
        }

        @media #{$mbp} {
            // font-size: 30px;
        }

        @media #{$lbp} {
            margin-bottom: 50px;
            // font-size: 30px;
        }
    }
}

.error-503 {
    img {
        margin-bottom: 40px;
        width: 140px;

        @media #{$mbp} {
            width: 160px;
        }

        @media #{$xbp} {
            width: 180px;

        }
    }

    h1 {
        font-size: 35px;
        margin-bottom: 10px;

        @media #{$sbp} {
            font-size: 40px;
        }

        @media #{$mbp} {
            font-size: 45px;
        }

        @media #{$lbp} {
            font-size: 50px;
            margin-bottom: 15px;
        }
    }

    h2 {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1.7;
        max-width: 210px;

        @include mq(440px) {
            max-width: none;
        }

        @media #{$sbp} {
            font-size: 18px;
        }
    }
}