.cctv-1 {
    @include margin;

    @include minmax(950px, 1139px) {
        padding: 100px 0px;
    }

    @media #{$lbp} {
        background: url('/img/cctv-1-bg.jpg') no-repeat right -50px bottom / 750px;
    }

    @media #{$xbp} {
    }

    @media #{$wbp} {
        background: url('/img/cctv-1-bg.jpg') no-repeat right bottom / 800px;
    }

    >div {
        margin-bottom: 0;
    }

    img {
        display: none;
    }

    p {
        @media #{$lbp} {
            max-width: 450px;
        }
    }
}

.cctv-3 {
    @include margin;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    position: relative;
    margin-top: -50px;
    z-index: -1;

    @media #{$mbp} {
        margin-top: -100px;
    }

    @media #{$xbp} {
        margin-top: -220px;
    }

    @media #{$wbp} {
        margin-top: -250px;
    }

    img {
        width: 100%;
        margin-bottom: 40px;

        @media #{$sbp} {
            margin-bottom: 50px;
        }

        @media #{$mbp} {
            margin-bottom: 70px;
        }

        @media #{$lbp} {
            margin-bottom: 0;
        }
    }

    h3, p {
        padding: 0 20px;
        max-width: 1200px;

        @media #{$xsbp} {
            padding: 0 30px;
        }

        @media #{$sbp} {
            padding: 0 50px;
        }

        @media #{$mbp} {
            padding: 0 60px;
        }

        @media #{$lbp} {
            padding: 0;
        }
    }

    .card {
        @media #{$lbp} {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            right: 80px;
        }

        @media #{$xbp} {
            width: 600px;
            right: 120px;
            margin-top: 60px;

        }
        @media #{$wbp} {
            width: 630px;
            right: 180px;
        }
    }
}