.projects-header {
    text-align: center;
    padding: 40px 20px;

    @media #{$sbp} {
        padding: 60px 20px;
    }

    @media #{$mbp} {
        padding: 80px 20px;
    }

    @media #{$wbp} {
        padding: 80px 20px 100px;
    }

    h1 {
        margin: 0 0 20px 0;
        animation: fadeUp 0.6s ease 0.4s both;
    }

    p {
        max-width: 320px;
        margin: 0 auto;
        font-size: 14px;
        opacity: 0.9;
        animation: fadeUp 0.6s ease 0.5s both;

        @media #{$sbp} {
            max-width: 440px;
            line-height: 1.9;
        }

        @media #{$lbp} {
            font-size: 15px;
            max-width: 500px;
        }

        @media #{$xbp} {
            max-width: 560px;
            font-size: 16px;
        }

        @media #{$wbp} {
            max-width: 760px;
        }
    }
}