.privacy-header {
    text-align: center;
    text-align: center;
    padding: 30px 20px;

    @media #{$sbp} {
        padding: 40px 20px;
    }

    @media #{$mbp} {
        padding: 50px 20px;
    }

    h1 {
        margin: 0 0 20px 0;
        animation: fadeUp 0.6s ease 0.4s both;
    }
}

.privacy {
    @include contain;
    @include margin;
    max-width: 1100px;

    h2 {
        @extend .heading-small;
        margin-top: 20px;

        @media #{$lbp} {
            margin-top: 30px;
        }

        @media #{$xbp} {
            margin-top: 40px;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        margin-top: 20px;
        font-size: 16px;

        @media #{$lbp} {
            margin-top: 30px;
            font-size: 18px;
        }

        @media #{$xbp} {
            margin-top: 40px;
            font-size: 20px;
        }
    }

    a {
        color: $colorPrimary;

        &:hover {
            opacity: 0.7;
        }
    }
}