footer {
    background: #000;
    text-align: center;
    @include paddingTop;
    @include paddingBottom;

    >div {
        @include contain;
    }

    .logo {
        display: block;
        margin: 0 auto 40px auto;
        width: 100px;

        @media #{$sbp} {
            margin: 0 auto 50px auto;
        }

        @media #{$mbp} {
            margin-bottom: 60px;
        }

        @media #{$lbp} {
            margin-bottom: 80px;
        }

        @media #{$xbp} {
            margin-bottom: 100px;
        }

        img {
            width: 100%;
        }
    }

    nav {
        text-align: left;

        @media #{$mbp} {
            display: inline-grid;
            grid-template-columns: auto auto auto;
            margin-bottom: 70px;
            grid-column-gap: 70px;
        }

        @media #{$lbp} {
            grid-column-gap: 85px;
            margin-bottom: 80px;
        }

        @media #{$xbp} {
            grid-column-gap: 100px;
            margin-bottom: 100px;
        }

        >div {
            @media #{$mbp} {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            > div {
                @media #{$mbp} {
                    margin-bottom: 30px;
                }

                @media #{$lbp} {
                    margin-bottom: 40px;
                }

                @media #{$xbp} {
                    margin-bottom: 60px;
                }

                &:last-child {
                    @media #{$mbp} {
                        margin-bottom: 0;
                    }
                }

                h5.heading-small {
                    margin-bottom: 10px;

                    @media #{$lbp} {
                        margin-bottom: 15px;
                    }

                    @media #{$wbp} {
                        margin-bottom: 20px;
                    }
                }
            }

            &:first-child, &:nth-child(2) {
                display: none;

                @media #{$mbp} {
                    display: flex;
                }
            }

            &:last-child {
                >a {
                    width: 150px;
                    display: block;
                    margin: 0 auto 30px;

                    @media #{$sbp} {
                        margin-bottom: 50px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        .legal {
            color: grey;
            text-align: center;

            @include mq(600px) {
                margin-bottom: 50px;
            }

            @media #{$mbp} {
                margin-bottom: 0;
                text-align: left;
            }

            a {
                color: inherit;
                font-size: 14px;
                margin: 5px;

                @media #{$mbp} {
                    margin-bottom: 10px;
                }

                &:hover {
                    color: $white;
                }
            }
        }

        a {
            margin-bottom: 10px;
            display: block;
            font-size: 15px;

            @media #{$mbp} {
                font-size: 16px;
            }

            @media #{$lbp} {
                margin-bottom: 15px;
            }

            @media #{$xbp} {
                margin-bottom: 15px;
            }

            &:last-child {
                @media #{$mbp} {
                    margin-bottom: 0;
                }
            }

            &:hover {
                color: $colorPrimary;
            }
        }

        h5.heading-small {
            margin-bottom: 0;

            a {
                @media #{$xbp} {
                    font-size: 20px;
                }
            }
        }
    }
}

.accreds {
    display: none;

    @include mq(600px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 700px;
    }

    @media #{$lbp} {
        max-width: 800px;
    }

    @media #{$xbp} {
        max-width: 900px;
    }

    a:hover {
        opacity: 0.7;
    }

    img {
        width: auto;
        max-height: 35px;
        max-width: 60px;
        display: block;

        @media #{$mbp} {
            max-height: 40px;
            max-width: 100px;
        }

        @media #{$lbp} {
            max-height: 50px;
        }

        @media #{$xbp} {
            max-height: 60px;
        }
    }

    div {
        margin: 0 10px;
    }
}