p,
ul,
ol,
address,
q,
cite {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.7;
    color: $white;
    font-weight: $light;

    @media #{$mbp} {
        line-height: 1.8;
    }

    @media #{$lbp} {
        font-size: 16px;
    }

    @media #{$xbp} {
        line-height: 1.9;
    }

    &:last-child:not(.senior-list) {
        margin-bottom: 0;
    }
}

ul,
ol {
    margin-left: 17px;
}

li {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.heading,
.heading-small {
    text-transform: uppercase;
    font-weight: $thin;
    line-height: 1.6;

    span {
        color: $colorPrimary;
        font-weight: 600;
    }
}

.heading {
    font-size: 14px;
    letter-spacing: 2px;

    @media #{$xsbp} {
        font-size: 16px;
        letter-spacing: 3px;
    }

    @media #{$sbp} {
        font-size: 18px;
        letter-spacing: 5px;
    }

    @include mq(600px) {
        font-size: 20px;
    }

    @media #{$mbp} {
        font-size: 24px;
        letter-spacing: 6px;
    }

    @media #{$lbp} {
        font-size: 26px;
        letter-spacing: 8px;
    }

    @media #{$xbp} {
        font-size: 28px;
        letter-spacing: 10px;
    }

    @media #{$wbp} {
        font-size: 30px;
    }
}

.heading-small {
    font-size: 13px;
    letter-spacing: 2px;

    @media #{$xsbp} {
        font-size: 14px;
    }

    @media #{$sbp} {
        letter-spacing: 3px;
        font-size: 17px;
    }

    @media #{$mbp} {
        font-size: 18px;
        letter-spacing: 4px;
    }

    @media #{$lbp} {
        font-size: 19px;
        letter-spacing: 6px;
    }

    @media #{$xbp} {
        font-size: 21px;
        margin-bottom: 20px;
    }

    @media #{$wbp} {
        font-size: 23px;
    }
}

.eyebrow {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background: $colorPrimary;
        width: 50px;
        height: 1px;
        top: -20px;
    }
}
