.service-list-stacked {
    ul {
        margin: 0;
        list-style: none;
    }

    li {
        margin-bottom: 15px;

        @media #{$xbp} {
            margin-bottom: 20px;
        }
    }

    a {
        display: flex;
        align-items: center;
        background-image: linear-gradient(180deg, #292929 0%, #121212 100%);
        box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        padding: 15px;

        @media #{$sbp} {
            padding: 15px 20px;
        }

        @media #{$wbp} {
            padding: 25px;
        }

        &:hover {
            transform: scale(1.02);

            h3 {
                text-shadow: 0 0 15px #fff, 0 0 15px rgba(255, 255, 255, 0.8);
            }
        }
    }

    h2 {
        @media #{$sbp} {
            margin-bottom: 20px;
        }

        @media #{$wbp} {
            margin-bottom: 30px;
        }
    }

    h3 {
        margin: 0;
    }

    img {
        width: 30px;
        height: 30px;
        margin-right: 15px;

        @media #{$sbp} {
            margin-right: 20px;
        }

        @media #{$mbp} {
            width: 35px;
            height: 35px;
        }

        @media #{$wbp} {
            width: 40px;
            height: 40px;
        }
    }
}

.service-label {
    font-size: 12px;
    letter-spacing: 2px;
    transition: 0.3s ease;
    margin: 0;
    text-transform: uppercase;
    font-weight: $light;

    @media #{$xsbp} {
        margin: 0 auto;
        line-height: 1.4;
    }

    @media #{$sbp} {
        max-width: none;
        font-size: 14px;
    }

    @media #{$mbp} {
        font-size: 12px;
    }

    @media #{$lbp} {
        font-size: 14px;
        letter-spacing: 3px;
    }

    @media #{$xbp} {
        font-size: 16px;
        letter-spacing: 4px;
    }

    @media #{$wbp} {
        font-size: 18px;
    }
}
