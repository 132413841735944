.nav-open {
    .main-nav {
        transform: translate3d(0, 0, 0);
    }
}

#menu-btn {
    display: block;
    background: none;
    width: 22px;

    @media #{$lbp} {
        width: 24px;
    }

    img {
        width: 100%;
    }
}

.main-nav {
    position: fixed;
    height: 100%;
    background: $black;
    top: 0;
    right: 0;
    width: 240px;
    transform: translate3d(240px, 0, 0);
    transition: all 0.3s ease-out;
    z-index: 8000;
    padding: 20px;
    padding-right: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;

    @media #{$mbp} {
        padding: 30px;
        padding-right: 0;
        width: 400px;
        transform: translate3d(400px, 0, 0);
    }

    nav {
        margin-bottom: 10px;

        > a {
            font-weight: 100;
            text-transform: uppercase;
            letter-spacing: 4px;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: 0;
            }
        }

        > div {
            border-top: 1px solid rgba($white, 0.1);
            padding-top: 10px;
            margin-top: 10px;

            @media #{$xbp} {
                padding-top: 15px;
                margin-bottom: 15px;
            }

            &:last-of-type {
                border-bottom: 1px solid rgba($white, 0.1);
                padding-bottom: 10px;
                margin-bottom: 10px;

                @media #{$xbp} {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        h5 {
            margin: 0 0 5px 0;
            font-weight: 100;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: flex;
            align-items: center;
            font-size: 15px;

            & + a {
                padding-top: 0;
            }

            a {
                border: 0;
            }

            img {
                margin-right: 10px;
                // width: 17px;
                height: 20px;
            }
        }

        a,
        button {
            display: block;
            box-shadow: none;
            text-align: left;
            font-size: 13px;
            padding: 7px 15px 7px 0;

            @media #{$mbp} {
                font-size: 14px;
            }

            @media #{$xbp} {
                font-size: 15px;
            }

            &:hover {
                color: $colorPrimary;
                transform: none;
                opacity: 0.8;
            }
        }

        .portal-link {
            display: flex;
        }

        button {
            cursor: pointer;
            text-transform: uppercase;
            color: $colorPrimary;
            letter-spacing: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 12px;

            @media #{$mbp} {
                font-size: 14px;
            }

            img {
                width: 6px;
                margin-right: 3px;
                transform: rotate(90deg);
                transition: transform 0.3s ease;

                @media #{$mbp} {
                    width: 7px;
                }

                &.open {
                    transform: rotate(270deg);
                }
            }
        }
    }

    > button {
        background: none;
        margin-left: auto;
        display: block;
        width: 15px;
        margin: 0 25px 0 auto;

        img {
            width: 100%;
            display: block;
        }
    }

    a.btn {
        text-align: center;
        font-size: 12px;
        width: calc(100% - 20px);

        &:hover {
            color: $white;
        }
    }

    .social {
        padding-top: 30px;

        a {
            margin-right: 15px;
            width: 25px;
        }
    }

    .nav-section {
        max-height: 0;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;

        a {
            padding-left: 20px;
        }
    }
}

.nav-wrapper {
    background-color: $black;
    position: sticky;
    top: 0;
    z-index: 5000;
    padding: 15px 0;

    @media #{$sbp} {
        padding: 20px 0;
    }

    @media #{$mbp} {
        padding: 20px 0;
    }

    @media #{$lbp} {
        padding: 20px 0;
    }

    @media #{$xbp} {
        padding: 25px 0;
    }

    @media #{$wbp} {
        padding: 35px 0;
    }

    > div {
        padding: 0 15px;
        max-width: 1300px;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media #{$mbp} {
            padding: 0 30px;
        }

        > div {
            flex-basis: 33.3%;

            &:first-child {
                display: flex;
            }
        }
    }

    .logo {
        width: 60px;
        animation: fadeIn 0.8s ease 0.4s both;
        display: block;
        margin: auto;

        @media #{$mbp} {
            width: 80px;
        }

        @media #{$xbp} {
            width: 100px;
        }

        @media #{$wbp} {
            width: 110px;
        }

        img {
            width: 100%;
        }
    }

    button {
        margin-left: auto;
    }

    nav {
        a {
            // text-transform: uppercase;
            // margin-left: 20px;

            // &:hover {
            //     color: $colorPrimary;
            // }
        }
    }
}

.portal-link {
    display: none;
    align-items: center;

    @media #{$mbp} {
        display: inline-flex;
    }

    span {
        font-size: 12px;
        text-transform: none;
        letter-spacing: 0;

        @media #{$lbp} {
            font-size: 14px;
        }
    }

    &:hover {
        opacity: 0.6;
    }

    img {
        margin-right: 7px;
        width: 20px;
        height: auto;

        @media #{$xbp} {
            width: 25px;
        }
    }
}
