.service-header {
    @include margin;
    text-align: center;
    background: url("/img/seamless.jpg") no-repeat center center / cover;
    display: flex;
    justify-content: center;
    padding: 60px 10px;
    animation: fadeIn 0.6s ease 0.4s both;

    @media #{$sbp} {
        padding: 80px 10px;
    }

    @media #{$mbp} {
        padding: 120px 10px;
    }

    @media #{$lbp} {
        padding: 165px 10px;
    }

    @media #{$xbp} {
        padding: 180px 0;
    }

    @media #{$wbp} {
        padding: 240px 0;
    }

    &.ha-header {
        background: url("/img/stevenage-1.jpg") no-repeat center center / cover;
    }

    &.lighting-header {
        background: url("/img/la-zagaleta-1-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/la-zagaleta-1-large.jpg") no-repeat center center / cover;
        }
    }

    &.control-header {
        background: url("/img/lambourne-hall-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/lambourne-hall-large.jpg") no-repeat center center / cover;
        }
    }

    &.home-cinema-header {
        background: url("/img/home-cinema-2-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/home-cinema-2-large.jpg") no-repeat center center / cover;
        }
    }

    &.electrial-commercial-header {
        background: url("/img/electrical-commercial-small.jpg") no-repeat top center / cover;

        @media #{$mbp} {
            background: url("/img/electrical-commercial-large.jpg") no-repeat top center / cover;
        }
    }

    &.electrial-domestic-header {
        background: url("/img/electrical-domestic-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/electrical-domestic-large.jpg") no-repeat center center / cover;
        }
    }

    &.access-control-header {
        background: url("/img/access-control-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/access-control-large.jpg") no-repeat center center / cover;
        }
    }

    &.cctv-header {
        background: url("/img/cctv-small.jpg?v=2") no-repeat top center / cover;

        @media #{$mbp} {
            background: url("/img/cctv-large.jpg") no-repeat top center / cover;
        }
    }

    &.fire-header {
        background: url("/img/fire-alarms-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/fire-alarms-large.jpg") no-repeat center center / cover;
        }
    }

    &.alarms-header {
        background: url("/img/alarms-small.jpg") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/alarms-large.jpg") no-repeat center center / cover;
        }
    }

    &.networking-header {
        background: url("/img/networking-small.jpg?v=2") no-repeat center center / cover;

        @media #{$mbp} {
            background: url("/img/networking-large.jpg") no-repeat center center / cover;
        }
    }

    h1 {
        margin: 0;
        padding: 10px 20px;
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.4);

        @supports (backdrop-filter: blur(14px)) {
            background: rgba($white, 0.1);
            backdrop-filter: blur(14px);
        }
    }
}

.service-main {
    @include contain;
    @include margin;
    text-align: center;

    .heading {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;

        @media #{$lbp} {
            max-width: 700px;
        }
    }

    p {
        max-width: 500px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;

        @media #{$mbp} {
            margin-bottom: 30px;
        }

        @media #{$lbp} {
            max-width: 700px;
        }
    }

    .service-list {
        @media #{$xsbp} {
            padding-top: 10px;
        }

        @media #{$sbp} {
            padding-top: 20px;
        }

        @media #{$mbp} {
            padding-top: 40px;
        }
    }
}

.service-list {
    text-align: center;

    @media #{$xsbp} {
        @supports (display: grid) {
            display: grid;
            grid-gap: 30px 20px;

            @media #{$xsbp} {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media #{$mbp} {
        display: flex;
        justify-content: center;
        grid-gap: 0;
        margin: 0 -10px;
    }

    @media #{$xbp} {
        margin: 0 -15px;
    }

    @media #{$wbp} {
        margin: 0 -25px;
    }
}

.service-list-item {
    display: block;
    margin-bottom: 30px;

    @media #{$xsbp} {
    }

    @media #{$mbp} {
        flex: 0 1 25%;
        margin: 0 10px;
    }

    @media #{$xbp} {
        margin: 0 15px;
    }

    @media #{$wbp} {
        margin: 0 25px;
    }

    @media #{$xsbp} {
        @supports (display: grid) {
            margin-bottom: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    > div {
        background-image: linear-gradient(180deg, #292929 0%, #121212 100%);
        box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        padding: 30px 5px;
        margin-bottom: 15px;
        transition: all 0.3s ease;

        @media #{$sbp} {
            padding: 40px 5px;
        }

        @media #{$xbp} {
            padding: 60px 15px;
            margin-bottom: 30px;
        }
    }

    &:hover {
        > div {
            transform: scale(1.05);
        }

        h3 {
            text-shadow: 0 0 15px #fff, 0 0 15px rgba(255, 255, 255, 0.8);
        }
    }

    img {
        filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.5));
        height: 35px;
        width: auto;

        @media #{$mbp} {
            height: 40px;
        }

        @media #{$lbp} {
        }
    }
}

.preview-list + .more-services {
    @media #{$xbp} {
        padding-top: 20px;
    }
}

.more-services {
    @include contain;
    @include margin;
    text-align: center;

    @media #{$sbp} {
        margin-bottom: 70px;
    }

    @media #{$mbp} {
        margin-bottom: 80px;
    }

    @media #{$lbp} {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    @media #{$wbp} {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    > div:first-child {
        margin-bottom: 30px;

        @media #{$xsbp} {
            margin-bottom: 40px;
        }

        @media #{$sbp} {
            margin-bottom: 50px;
        }

        @media #{$mbp} {
        }

        @media #{$xbp} {
            margin-bottom: 70px;
        }
    }

    p {
        max-width: 305px;
        margin: 0 auto;

        @media #{$mbp} {
            max-width: 500px;
        }
    }
}

.center-copy {
    @include contain;
    @include margin;

    @media #{$lbp} {
        text-align: center;
    }

    > div {
        max-width: 820px;
        margin: auto;
    }
}